// .card {
//   background-color: theme('colors.neutral.800');
// }
.quickScan-table {
  background-color: theme('colors.neutral.900');
  color: theme('colors.white');
  border-radius: 10px;
  padding: 24px;
  margin: 32px 0 16px 0;
  display: flex;
  flex-direction: column;
  width: calc(100% / 2 - 16px);
  min-width: 560px;

  &:first-of-type {
    margin-right: 16px;
  }

  &__title {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 20px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
    }
  }

  &__subtitle {
    color: theme('colors.neutral.400');
    font-size: 14px;
  }

  &__time-frames {
    display: flex;
    justify-content: space-around;
    margin: 16px 0 8px;
  }

  &__time-frame {
    background-color: theme('colors.neutral.900');
    color: theme('colors.white');
    padding: 4px 8px;
    border-radius: 4px;
    font-weight: 700;
    cursor: pointer;
    transition: all 0.3s;
    width: 80px;
    text-align: center;

    &--active {
      background-color: theme('colors.violet.600');
      color: theme('colors.neutral.900');
    }
  }

  &__table {
    margin: 16px 0;

    thead {
      border-bottom: 0.5px solid theme('colors.neutral.400');
    }

    th,
    td {
      padding: 8px 16px;
      text-align: center;
    }

    th {
      color: theme('colors.neutral.400');
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 400;
    }

    tbody tr:hover {
      opacity: 0.7;
    }
  }

  &__th {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__sort-icon {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 4px;
  }
}
