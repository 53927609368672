@import 'fonts';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: iransans, sans-serif;
}

* {
  outline: none;
}

.highcharts-credits {
  display: none;
}

/* change scrollbar default styles */
html {
  background-color: theme('colors.black');
  --scrollbarBG: theme('colors.black');
  --thumbBG: theme('colors.neutral.500');
}
*::-webkit-scrollbar {
  width: 14px;
}
* {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
*::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
*::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 8px;
  border: 3px solid var(--scrollbarBG);
}
