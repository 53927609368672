.fin-title {
  padding: 12px 0;

  &__title {
    font-size: 20px;
    font-weight: 600;
  }

  &__subtitle {
    font-size: 14px;
    margin-top: 4px;
  }

  &__icon-wrapper {
    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
    }
  }
}

.fin-page-title {
  padding: 16px 0;

  &__title {
    font-size: 24px;
    font-weight: 700;
  }

  &__subtitle {
    font-weight: 500;
    font-size: 16px;
    margin-top: 4px;
  }
}
