.coin-header-card {
  border-radius: 8px;
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: center;
  min-width: 55px;
  background-color: theme('colors.neutral.900');

  &__name {
    font-size: 8px;
    margin-bottom: 4px;
  }

  &__value {
    font-size: 18px;
    font-weight: 500;
  }

  &__change {
    font-weight: 700;
    font-size: 18px;

    &.red {
      color: theme('colors.red.500');
    }

    &.green {
      color: theme('colors.green.500');
    }
  }
}

@media (max-width: 768px) {
  .coin-summary-header-data {
    flex-direction: column;
  }
}
