@media (max-width: 768px) {
  .news-table {
    overflow-x: scroll;
    &__table {
      td {
        white-space: nowrap;
        text-align: left !important;
      }
    }
  }
}

.news-table {
  &::-webkit-scrollbar {
    width: 14px;
  }
  & {
    scrollbar-width: thin;
    scrollbar-color: theme('colors.neutral.500') transparent;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 16px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: theme('colors.neutral.500');
    border-radius: 8px;
    border: 3px solid theme('colors.neutral.800');
  }

  box-sizing: border-box;
  // background-color: theme('colors.neutral.800');
  // color: theme('colors.white');
  border-radius: 10px;
  padding: 24px;
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  width: 100%;

  &__title {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 15px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
    }
  }

  &__subtitle {
    color: theme('colors.neutral.500');
    font-size: 14px;
  }

  &__table {
    margin: 16px 0;

    thead {
      border-bottom: 0.5px solid theme('colors.neutral.500');
    }

    th,
    td {
      padding: 8px 16px;
      text-align: left;
    }
    td {
      cursor: pointer;
    }
    th {
      color: theme('colors.neutral.500');
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 400;
    }

    tbody tr:hover {
      opacity: 0.7;
    }
  }

  &__th {
    display: flex;
    align-items: left;
    justify-content: center;
  }

  &__sort-icon {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 4px;
  }

  &__badges {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
  }

  &__coin,
  &__somewhat-Bullish,
  &__negative,
  &__neutral,
  &__bullish,
  &__bearish,
  &__somewhat-Bearish {
    padding: 4px 4px;
    // margin-right: 12px;
    border-radius: 4px;
    font-weight: 700;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
  }

  &__coin {
    background-color: theme('colors.dark.primary');
  }

  &__somewhat-Bullish {
    background-color: theme('colors.green.400');
  }

  &__neutral {
    background-color: theme('colors.neutral.500');
  }
  &__bullish {
    background-color: theme('colors.green.500');
  }
  &__bearish {
    background-color: theme('colors.red.600');
  }
  &__somewhat-Bearish {
    background-color: theme('colors.red.400');
  }

  &__pagination-wrapper {
    font-size: 14px;
    display: flex;
    align-items: center;
    margin: auto;
  }

  &__pagination {
    margin-left: 64px;
  }
}
.news-table__thumbnail {
  width: 50px; /* Adjust the width and height as desired */
  height: 50px;
  object-fit: cover;
  border-radius: 5%;
}
