.input__container2 {
  margin-top: 16px;
  width: 100%;
  font-size: 18px;
}

.input2 {
  font-size: 16px;
  width: 100%;
  border-radius: 8px;
  padding: 12px;
  color: theme('colors.neutral.800');
  background-color: theme('colors.neutral.100');
  margin-top: 8px;
  border: 1px solid theme('colors.neutral.300');
  outline: none;

  &:focus {
    border: 1.5px solid theme('colors.light.back');
  }

  &::placeholder {
    color: theme('colors.neutral.400');
  }
}

.input--disabled2 {
  color: theme('colors.neutral.500');
}

.input--error2,
.input--error2:focus {
  border: 1.5px solid theme('colors.red.500');
}

.input__text--error2 {
  color: theme('colors.red.500');
  margin: 4px 8px 0;
  font-size: 14px;
  font-weight: 600;
}

.input__label2 {
  margin: 8px;
  font-weight: 500;
}
