.market-overview {
  // max-width: 1320px;
  margin: auto;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__searchbar {
    position: relative;
  }

  &__main {
    display: flex;
    flex-direction: column;
    padding-top: 16px;
  }

  &__trading-view {
    height: 50vh;
  }

  &__topSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &-SubSection {
      width: 65%;
      display: flex;
      flex-direction: column;
      align-items: normal;
      justify-content: space-between;
    }
  }

  &__market-cap-chart {
    width: 100% !important;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: nowrap;
    margin-bottom: 16px;
  }

  &__treemap-price {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 16px 0;
    width: 100%;
  }

  &__biggest-tables {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
  }

  &__page-loading {
    height: calc(100vh - 96px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .market-overview {
    &__header {
      flex-direction: column-reverse;
    }

    &__searchbar {
      width: 100%;
    }

    &__topSection {
      flex-direction: column;

      &-SubSection {
        width: 100%;
      }
    }
  }
}
