.progress-bar {
  width: 100%;
  height: 35px;
  border-radius: 3px;
  overflow: hidden;
  position: relative;

  .progress-bar__label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
  }

  .progress-bar__fill {
    height: 100%;
    transition: width 0.3s ease-in-out;

    &.complete {
      background-color: #00b894; // Green color when progress is 100%
      .progress-bar__label {
        color: #fff; // White color when progress is 100%
      }
    }

    &:not(.complete) {
      background-image: linear-gradient(
        to right,
        #ff6b6b,
        #ff3d3d
      ); // Red gradient when progress is not 100%
    }
  }
}
