.input__container {
  width: 100%;
  font-size: small;
}
.input {
  border-radius: 10px;
  background: theme('colors.light.back_secondary');
  font-size: 16px;
  width: 100%;
  padding: 6px 8px;
  color: theme('colors.white');
  outline: none;

  &::placeholder {
    color: theme('colors.neutral.400');
    font-style: italic;
  }
}

.input__text--error {
  color: theme('colors.red.500');
  margin: 4px 8px 0;
  font-size: 14px;
  font-weight: 600;
}

.input__label {
  font-size: medium;
}

.fin-input {
  position: relative;
  width: 100%;
}