// select conmponent
// .select-div {
  // color: theme('colors.white');
// }
.select-div > div:nth-of-type(1) {
  border: solid 2px transparent;
  border-radius: 5px;
  box-shadow: none;
  width: 200px;
  text-align: center;
  font-size: medium;
  // color: theme('colors.white');
  background-color: theme('colors.neutral.900');
}

// drop down component
.select-div > div:nth-of-type(2) {
  background-color: theme('colors.black');
  width: 200px;
}
.select-div > div:nth-of-type(2):hover {
  border: solid 1px theme('colors.neutral.900');
  background-color: theme('colors.black');
}

// items of drop down component
.select-div > div:nth-of-type(2) > div {
  border-radius: 5px;
  // color: theme('colors.white');
  font-size: small;
  background-color: theme('colors.neutral.900');
}
.select-div > div:nth-of-type(2) > div > div:hover {
  background-color: theme('colors.neutral.900');
  // color: theme('colors.white');
}
.select-div > div:nth-of-type(2) > div > div:active {
  background-color: theme('colors.neutral.900');
}

.css-1u9des2-indicatorSeparator {
  display: none !important;
  background-color: theme('colors.black');
}
// color of text befor selecting item
// .css-1dimb5e-singleValue {
//   color: theme('colors.white') !important;
// }
//color of text after selected
// .css-1jqq78o-placeholder {
//   color: theme('colors.white') !important;
// }

// .css-tr4s17-option {
//   background-color: theme('colors.white') !important;
// }
