.aside {
  ::-webkit-scrollbar {
    width: 8px;
    height: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: theme('colors.light.side');
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    background-color: rgba(0, 146, 165, 0);
  }
}

.toggleDarkBtn {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 30px;
}
.toggleDarkBtn input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slideBtnTg {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: primary;
}
.slideBtnTg:before {
  position: absolute;
  content: '';
  height: 25px;
  width: 25px;
  left: 5px;
  bottom: 2.5px;
  background-color: theme('colors.white');
}
input:checked + .slideBtnTg {
  background-color: theme('colors.light.back_secondary');
}
input:focus + .slideBtnTg {
  box-shadow: 0 0 1px theme('colors.light.side');
}

.slideBtnTg.round {
  border-radius: 34px;
}
.slideBtnTg.round:before {
  border-radius: 50%;
}
.bordering {
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 5px;
  border-radius: 10px;
}
.bg-color {
  background-color: theme('colors.black');
}

.aside {
  background-color: theme('colors.black');
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  bottom: 0;
}

.aside .menu-item h2 {
  transition: opacity 0.5s ease-in-out;
}

.userInfo {
  transition: opacity 1s ease-in-out;
}

.sidebar-divider {
  border-top: 1px solid #ddd;
  margin-top: 20px;
  padding-top: 20px;
  height: 100%;
}

@media (max-width: 768px) {
  .aside {
    display: none;
  }
}

.sidebar-mobile {
  padding: 0 16px;
  height: 64px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background-color: theme('colors.neutral.800');
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__logo {
    display: flex;
    align-items: center;
  }

  &__logo-text {
    font-size: 22px;
    font-weight: 600;
    margin: 8px 0 0 4px;
  }

  &__content {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    z-index: 99;
    display: none;
    // background-color: theme('colors.neutral.800');
    overflow: hidden;
    padding: 12px 16px;

    &--open {
      display: unset;
    }
  }

  &__content-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 8px;
  }

  &__user {
    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
    }
  }
}


/* Styles for the switch container */
.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 19px;
}

/* Styles for the slider */
.slider {
  cursor: pointer;
  background-color: rgb(233, 15, 15);
  -webkit-transition: .4s;
  transition: .4s;
}

/* Styles for the round slider handle */
.round {
  border-radius: 10px;
}

/* Styling for the checked (on) state */
input:checked + .slider {
  background-color: white;
}

/* Styling for the checked (on) state of the round handle */
input:checked + .slider:before {
  transform: translateX(16px);
}


.menu-secondary-enter {
  opacity: 0;
  transform: scale(0.9);
}

.menu-secondary-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.menu-secondary-exit {
  opacity: 1;
}

.menu-secondary-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
