.coin-comparisons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  &__Chart {
    width: calc(100% / 2 - 10px);
    // background-color: theme('colors.neutral.800') !important;
    border-radius: 10px;
    color: theme('colors.white');
    &-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &--title {
        display: flex;
        font-size: xx-large;
        font-size: 20px;
        font-weight: 600;
        text-transform: uppercase;
        align-items: center;
      }
      &--search {
        display: flex;
      }
    }
    &-description {
      margin: 10px 0 0;
      color: theme('colors.neutral.400');
      font-size: 14px;
      &--text {
        font-size: 12px;
        color: theme('colors.gray.400');
      }
    }
  }
}
@media (max-width: 768px) {
  .coin-comparisons {
    display: unset;
    &__Chart {
      width: unset;
      padding: 1px;
      margin-bottom: 10px;
      &-header {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &--title {
          display: flex;
          font-size: xx-large;
          font-size: 15px;
          font-weight: 600;
          text-transform: uppercase;
          align-items: center;
        }
        &--search {
          display: flex;
        }
      }
      &-description {
        margin: 10px 0 0;
        color: theme('colors.neutral.400');
        font-size: 14px;
        &--text {
          font-size: 12px;
          color: theme('colors.gray.400');
        }
      }
    }
  }
}
.comparison_chart_holder {
  margin-bottom: 10px;
}

.comparison_button {
  display: flex;
  background-color: theme('colors.gray.600');
  color: theme('colors.white');
  padding: 5px;
  justify-content: center;
  height: 30px;
  font-style: italic;
}
