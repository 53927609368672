.login {
  display: flex;
  flex-direction: column;
  color: theme('colors.black');
  background-color: theme('colors.neutral.900');
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding-bottom: 32px;

  &__h1 {
    background-clip: text;
    // background-image: linear-gradient(
    //   to right,
    //   theme('colors.emerald.400'),
    //   theme('colors.sky.400'),
    //   theme('colors.pink.400'),
    //   theme('colors.yellow.400')
    // );
    color: theme('colors.white');
    font-size: 56px !important;
    font-weight: 900;
    padding: 16px;
    letter-spacing: 1.5px;
    -webkit-text-stroke-color: transparent;
    -webkit-text-stroke-width: 3px;
  }

  &__form {
    background-color: theme('colors.white');
    width: 400px;
    max-width: calc(100% - 32px);
    border-radius: 16px;
    box-shadow: 0 0 8px 8px rgba(0, 0, 0, 0.05);
    padding: 32px;
  }

  &__form-title {
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    margin-bottom: 32px;
  }

  &__signup {
    margin-top: 20px;
    font-size: 16px;
    font-weight: 500;
  }

  &__signup-link {
    text-decoration: none;
    color: theme('colors.dark.primary');
    font-weight: 500;
  }

  &__submit {
    background-color: theme('colors.dark.primary') !important;
    color: theme('colors.white');
    border: none;
    padding: 12px;
    font-size: 16px;
    font-weight: 700;
    border-radius: 8px;
    cursor: pointer;
    width: 100%;
    margin-top: 24px;
  }

  &__message {
    font-weight: 300;
    font-size: 14px;
    text-align: justify;
    margin-top: 12px;
  }
}

.react-loading {
  display: flex;
  justify-content: center;
}
