.treemap {
  border-radius: 8px;

  .highcharts-breadcrumbs-button.highcharts-button text {
    fill: theme('colors.white') !important;
  }

  .highcharts-breadcrumbs-button.highcharts-button-hover {
    text {
      fill: theme('colors.neutral.400') !important;
    }

    rect {
      fill: none;
    }
  }
}
