.card {
  background-color: theme('colors.light.back_secondary');
}
.title {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 20px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

.text-over {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: pre-wrap;
  text-align: justify;
}
.scroller {
  width: 300px;
  height: 100px;
  overflow-y: scroll;
  scrollbar-width: thin;
}

.market-news {
  width: 33.3%;
  &::-webkit-scrollbar {
    width: 14px;
  }
  & {
    scrollbar-width: thin;
    scrollbar-color: theme('colors.neutral.500') transparent;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 16px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: theme('colors.neutral.500');
    border-radius: 8px;
  }
}

@media (max-width: 768px) {
  .market-news {
    width: 100%;
    margin-top: 16px;
  }
}
