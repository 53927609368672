.dashboard-layout {
  display: flex;
  background-color: theme('colors.black');
  position: relative;
  width: 100vw;
  max-width: 100vw;
  overflow-x: hidden;

  &__top {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    background-image: linear-gradient(
      to top left,
      rgb(3, 169, 244),
      rgba(2, 136, 209)
    );
    height: 25vh;
  }

  &__main {
    z-index: 9;
    width: 100%;
    padding-left: 64px;
    height: 100vh;
    padding-top: 30px;
    background-color: theme('colors.black');
    overflow-y: auto;
  }

  &__main-wrapper {
    width: calc(100% - 128px);
    max-width: 1320px;
    padding: 16px;
    // overflow-x: hidden;
    // overflow-y: hidden;
    margin: auto;
    // height: 100%;
  }
}

@media (max-width: 768px) {
  .dashboard-layout {
    flex-direction: column;
    height: 100vh;
    overflow-y: hidden;

    &__main {
      padding-left: 0;
      height: calc(100vh - 64px);
      overflow-x: hidden;
    }

    &__main-wrapper {
      width: 100%;
      padding: 16px;
    }

    &__main {
      margin-top: 64px;
    }
  }
}
