.footer {
  &__copyright {
    color: theme('colors.white');
    text-align: center;
    margin: 16px 0 24px;
    font-size: 14px;

    a {
      color: theme('colors.dark.primary');
    }
  }
}
