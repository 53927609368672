.WizardPage-Titles {
  margin-top: 30px;
  // color: theme('colors.white');
}
.WizardPage-Titles2 {
  color: theme('colors.white');
  font-size: 20px;
  margin-top: 20px;
  margin-left: 20px;
}
.WizardPage-Description {
  color: theme('colors.neutral.500');
  font-size: 10px;
  margin-left: 20px;
}
.WizardPage-Profitable-Combinantions {
  display: flex;
}
.StartegyPage-Summery {
  // background-color: theme('colors.neutral.900');
  border-radius: 10px;
  margin-top: 20px;
}
.StartegyPage-Summery-First-Row {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}
.SatrtegyPage-Performance-Chart {
  margin-top: 10px;
  padding: 3px;
  // background-color: theme('colors.neutral.900');
  border-radius: 10px;
}
.WizardPage-Performance-Summery {
  margin-top: 30px;
  border-radius: 10px;
}
.WizardPage-Profitable-Combinantions {
  background-color: theme('colors.neutral.800');
}
.WizardPage-Profitable-Coins {
  background-color: theme('colors.neutral.900');
}
.WizardPage-Recent-signals {
  background-color: theme('colors.neutral.900');
}
.StartegyPage-Tags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 20px;
}
.card {
  background-color: theme('colors.neutral.800');
}
.WizardPage-Inputs-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.WizardPage-text {
  color: theme('colors.white');
  font-size: 18px;
  width: 200px;
}
.WizardPage-description {
  color: theme('colors.neutral.500');
  font-size: 13px;
  width: 200px;
}
.SP-C-green {
  color: theme('colors.green.500');
}
.SP-C-red {
  color: theme('colors.red.500');
}
.SP-C-white {
  color: theme('colors.white');
}
.WizardPage-text-and-description {
  align-content: flex-start;
  padding-left: 0px;
  font-size: larger;
  width: 200px !important;
}

.strategyPage-loading {
  height: calc(100vh - 96px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
