//titles:
.title1 {
  color: theme('colors.white');
  font-size: 25px;
  margin-left: 15px;
}
.title2 {
  color: theme('colors.white');
  margin-left: 30px;
  margin-top: 15px;
}
.div-titles {
  font-size: 20px;
  margin-top: 10px;
  margin-left: 10px;
  text-align: center;
}
//divs:
.main-div {
  padding-top: 30px;
}
.first-div {
  color: theme('colors.white');
  border-radius: 10px;
}
.second-div {
  color: theme('colors.white');
  border-radius: 10px;
  padding: 16px;
  // align-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // width: 100%;
}
.third-div {
  color: theme('colors.white');
  margin-top: 30px;
  border-radius: 10px;
}
.first-row-select {
  margin: 15px;
  margin-top: 20px;
  justify-content: center;
  gap: 15px;
  display: flex;
}
.second-row-input {
  margin-top: 30px;
  margin-right: 30px;
  margin-left: 30px;
  margin-bottom: -10px;
  display: flex;
  justify-content: center;
  gap: 15px;
}
.div-titles1 {
  text-align: center;
  font-size: 20px;
  margin-top: 10px;
  margin-left: 10px;
}
.portfolio-table {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.Chart-div-portfolio1 {
  border-radius: 10px;
  margin: 10px;
  flex-grow: 1;
}
.Chart-div-portfolio2 {
  border-radius: 10px;
  margin: 10px;
}
.Charts-div-portfolio-row {
  justify-content: center;
  display: flex;
}
.asset-portfolio {
  padding-top: 8px;
}
#scatter-portfolio {
  border-radius: 10px !important;
  background-color: theme('colors.green.500') !important;
}

.model-config {
  display: flex;
  flex-wrap: wrap;
}