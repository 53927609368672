.smart-portfolio {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px 0 64px;
    // max-width: 1320px;
    margin: 20px;
  
    &__settings {
      background-color: theme('colors.neutral.800');
      color: theme('colors.white');
      border-radius: 8px;
      padding: 16px;
      text-align: center;
      overflow-x: auto;
    }
  
    &__settings-top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
    }
  
    &__settings-title {
      font-weight: 600;
      font-size: 18px;
      display: flex;
      align-items: center;
    }
  
    &__settings-title-icon {
      font-size: 24px;
      margin-left: 4px;
      cursor: pointer;
    }
  
    &__row,
    &__header {
      display: flex;
      align-items: center;
      width: 80%;
    }
  
    &__row {
      width: 100%;
    }
  
    &__header {
      font-weight: 600;
    }
  
    &__row-left,
    &__header-left {
      display: flex;
      align-items: center;
      flex-grow: 1;
      text-align: center;
    }
  
    &__row-left {
      // background-color: theme('colors.neutral.900');
      border-radius: 8px;
      margin-right: 8px;
      padding: 12px;
    }
  
    &__row,
    &__header {
      margin: 6px 0;
    }
  
    &__header-left {
      padding: 0 16px;
      margin: 0 16px 0 0;
    }
  
    &__row-col,
    &__header-col {
      width: 25%;
      margin: 0 6px;
    }
  
    &__row-col {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    &__row-delete-btn {
      color: theme('colors.red.500');
      font-size: 24px;
      cursor: pointer;
    }
  
    &__bottom-row {
      .smart-portfolio__row-left {
        padding: 0;
        background-color: transparent;
      }
    }
  
    &__add-strategy {
      border: 1px solid theme('colors.dark.primary');
      width: 100%;
      padding: 10px;
      border-radius: 8px;
      color: theme('colors.dark.primary');
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  
    &__add-strategy-icon {
      font-size: 20px;
      margin-right: 8px;
    }
  
    &__backtest-settings {
      background-color: theme('colors.neutral.800');
      color: theme('colors.white');
      border-radius: 8px;
      padding: 16px;
      margin-top: 16px;
      overflow-x: auto;
    }
  
    &__backtest-settings-title {
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 16px;
    }
  
    &__backtest-filters {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      margin-top: 8px;
    }
  
    &__backtest-btn {
      background-color: theme('colors.dark.primary');
      padding: 8px 16px;
      font-weight: 600;
      outline: none;
      border-radius: 8px;
    }
  
    &__initial-capital-input {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    &__allocation-mode {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  
    &__allocation-mode-label {
      font-weight: 600;
    }
  
    &__allocation-mode-select {
      & > div:nth-of-type(1) {
        background: theme('colors.dark.primary');
        border-radius: 10px;
        border: none;
        width: 200px;
        text-align: center;
        color: theme('colors.white');
      }
    }
  }
  
  .blurry-container {
    justify-content: center;
    padding: 16px 0 64px;
    // max-width: 1320px;
    margin: 20px;
    border-radius: 5px;
    color: white;
    background-color: theme('colors.light.side'); /* Add a semi-transparent background color */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9999; /* Ensure the container is on top of other elements */
  }
  
  .blurry-container h1,
  .blurry-container p {
    color: #000; /* Set the text color */
  }
  
  .blurry-container h1 {
    font-size: 24px; /* Adjust the font size as needed */
    font-weight: bold;
  }
  
  .blurry-container p {
    font-size: 16px; /* Adjust the font size as needed */
  }