.calendar-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__label {
    font-size: 12px;
    font-weight: 500;
  }

  &__container {
    display: flex;
    align-items: center;
  }

  &__input {
    max-width: 128px;
    text-align: center;
  }

  &__icon {
    margin-right: 6px;
  }
}
