.pagination-bar {
  width: 90%;
  display: flex;
  justify-content: right;
  padding: 3px;
}

.table_width {
  min-width: 300px;
}
