// .table-style {
//   font-size: 800px;
// }
// .green {
//   color: theme('colors.green.500') !important;
// }

// .red {
//   color: theme('colors.red.500') !important;
// }
// .card-size {
//   height: 15rem;
// }
// .correlation {
//   &__pagination-wrapper {
//     font-size: 14px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     width: 100%;
//   }

//   &__pagination {
//     margin-left: 64px;
//   }
// }
.gauge-charts {
  color: theme('colors.white');
  margin: 32px 0;
  z-index: -1;
  position: relative;
  display: flex;
  justify-content: center;

  &__time-frames {
    display: flex;
    justify-content: space-around;
    margin: 16px auto;
    width: 100%;
    max-width: 800px;
  }

  &__time-frame {
    color: theme('colors.white');
    padding: 4px 8px;
    border-radius: 4px;
    font-weight: 700;
    cursor: pointer;
    transition: all 0.3s;
    width: 96px;
    text-align: center;

    &--active {
      color: theme('colors.neutral.900');
    }
  }
}

.correlation-table {
  &::-webkit-scrollbar {
    width: 14px;
  }
  & {
    scrollbar-width: thin;
    scrollbar-color: theme('colors.neutral.500') transparent;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 16px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: theme('colors.neutral.500');
    border-radius: 8px;
    border: 3px solid theme('colors.neutral.800');
  }
}
