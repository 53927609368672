.coin-container {
  padding: 5vh;

  &__tv-chart {
    height: 60vh;
  }
}

.sectionOne {
  width: 100%;
  &__marketInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 5px;
    flex-wrap: wrap;
    &-info {
      background-color: theme('colors.neutral.900');
      width: calc(100% / 4 - 10px);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 2% 1%;
      border-radius: 10px;
      &--rightBorder {
        border-right: 0.5px;
        border-color: theme('colors.neutral.900');
        border-style: ridge;
      }
      &--bottomBorder {
        border-bottom: 0.5px;
        border-color: theme('colors.neutral.900');
        border-style: ridge;
      }
    }
  }
}

.infoContainer {
  &__chart {
    width: 60% !important;
  }
  &__infoHeader {
    // color: theme('colors.white');
    text-transform: uppercase;
    font-size: 20px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    &-chart {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
  &__subtitle {
    color: theme('colors.neutral.500');
    font-size: 15px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }
  &__badges {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    &-badge {
      border-radius: 2px;
      // background-color: theme('colors.neutral.900');
      padding: 0 8px;
      text-align: center;
      display: flex;
      flex-direction: row;
      align-items: center;
      &--key {
        color: theme('colors.neutral.500');
        font-size: 85%;
      }
      &--value {
        // color: theme('colors.white');
        font-size: 85%;
        padding: 2px;
      }
      &--redValue {
        color: theme('colors.red.500');
        font-size: 85%;
        padding: 2px;
      }
      &--greenValue {
        color: theme('colors.green.500');
        font-size: 85%;
        padding: 2px;
      }
    }
  }
  &__description {
    font-weight: 600;
    color: theme('colors.neutral.500');
    margin-top: 20px;
    display: flex;
    align-items: center;
    text-align: center;
  }
}

.coin-charts {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100% !important;
  background-color: theme('colors.neutral.900');
  border-radius: 5px;
  flex-wrap: wrap;
  &_chart {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1%;
    background-color: theme('colors.neutral.900');
  }
}

.coin-card {
  background-color: theme('colors.neutral.900') !important;
}
.coin_items {
  width: 100%;
  align-self: center;
}

.dashboards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2%;
}
.dashboard_item {
  width: 49%;
  background-color: theme('colors.gray.200');
  border-radius: 5px;
  padding: 1%;
}
.technical_dashboard_buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1%;
}
.technical_dashboard_items {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 2%;
}

.timeframe_button {
  display: flex;
  background-color: theme('colors.gray.400');
  color: theme('colors.white');
  border-radius: 5px;
  width: 15%;
  height: 2%;
  padding: 5px;
  justify-content: center;
}

.correlatedCion {
  display: flex;
  justify-content: space-between;
}

.correlation_items {
  width: 49%;
  background-color: theme('colors.gray.200');
  border-radius: 10px;
  padding: 1%;
  margin-top: 2%;
}

.correlation_chart_holder {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.latest_news {
  display: flex;
  justify-content: center;
}

.latest_news_items {
  width: 100%;
  background-color: theme('colors.gray.200');
  border-radius: 10px;
  padding: 1%;
  margin-top: 2%;
}

.coin {
  &__page-loading {
    height: calc(100vh - 96px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__time-frame-wrapper {
    width: 128px !important;
    margin: 16px 0;
  }

  &__time-frame {
    font-size: 14px !important;
  }

  &__gauge-tables {
    display: flex;
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .sectionOne {
    &__marketInfo {
      flex-direction: column;
      // justify-content: space-between;
      border-radius: 5px;
      // flex-wrap: wrap;
      &-info {
        background-color: theme('colors.neutral.900');
        width: 100%;
        flex-direction: column;
        align-items: center;
        padding: 2% 1%;
        border-radius: 10px;
        margin-top: 20px;
        &--rightBorder {
          border-right: 0.5px;
          border-color: theme('colors.neutral.800');
          border-style: ridge;
        }
        &--bottomBorder {
          border-bottom: 0.5px;
          border-color: theme('colors.neutral.800');
          border-style: ridge;
        }
      }
    }
  }

  .coin {
    &__gauge-tables {
      flex-direction: column;
    }
  }
}


.gauge-chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__title {
    z-index: 2;
    font-weight: 700;
    font-size: 18px;
  }

  &__items {
    display: flex;
    z-index: 2;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 16px;
    font-weight: 700;
  }

  &__sell {
    color: theme('colors.red.500');
  }

  &__neutral {
    color: theme('colors.neutral.500');
  }

  &__buy {
    color: theme('colors.dark.primary');
  }
}

@keyframes tickAnimation {
  0% {
    transform: translateX(-2px) scale(0);
  }
  50% {
    transform: translateX(2px) scale(0.7);
  }
  100% {
    transform: translateX(0) scale(1);
  }
}

.tick {
  animation: tickAnimation 1.5s ease-out forwards;
}

.tick--active {
  animation-delay: 0.3s;
}


.gauge-table {
  color: theme('colors.white');
  width: 50%;
  margin: 16px;

  &__title {
    font-weight: 700;
    font-size: 18px;
    margin-left: 16px;
  }

  &__table {
    margin: 8px 0 16px;
    width: 100%;

    thead {
      border-top: 0.5px solid theme('colors.neutral.300');
    }

    tr {
      border-bottom: 0.5px solid theme('colors.neutral.300');
    }

    th,
    td {
      padding: 8px 16px;
      text-align: left;
    }

    th {
      color: theme('colors.neutral.500');
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 600;
    }

    tbody tr:hover {
      opacity: 0.7;
    }
  }

  &__action {
    font-weight: 700;
  }

  &__buy {
    color: theme('colors.dark.primary');
  }

  &__sell {
    color: theme('colors.red.500');
  }

  &__neutral {
    color: theme('colors.neutral.500');
  }
}
