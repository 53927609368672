.bottom-select {
  &__container {
    display: flex;
    flex-direction: column;
    width: 160px;
    font-family: 'Courier New', Courier, monospace;
  }

  &__label {
    margin-left: 5px;
    font-size: 12px;
    font-weight: 500;
  }

  &__option {
    background-color: theme('colors.neutral.900') !important;

    &:hover {
      background-color: theme('colors.neutral.700') !important;
    }

    &--active {
      background-color: theme('colors.dark.primary') !important;

      &:hover {
        background-color: theme('colors.dark.primary') !important;
      }
    }
  }

  & > div:nth-of-type(1) {
    background: transparent;
    border-radius: 0;
    border: none;
    border-bottom: 2px solid theme('colors.white');
    box-shadow: none !important;
    width: 200px;
    text-align: center;
  }

  &--is-filled {
    & > div:nth-of-type(1) {
      background: theme('colors.light.back_secondary');
      border-radius: 10px;
      border: none;
      width: 200px;
      text-align: center;
    }
  }
}

.bottom-select > div {
  background: theme('colors.light.back_secondary');
}
