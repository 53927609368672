.recent-signals-wrapper {
  &::-webkit-scrollbar {
    width: 14px;
  }
  & {
    scrollbar-width: thin;
    scrollbar-color: theme('colors.neutral.500') transparent;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 16px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: theme('colors.neutral.500');
    border-radius: 8px;
    border: 3px solid theme('colors.neutral.800');
  }
}
