.overal-market-header-card {
  border-radius: 8px;
  padding: 16px;

  &__symbol {
    font-weight: 700;
    margin-left: 8px;
  }

  &__logo {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    margin-bottom: 6px;
  }

  &__content {
    display: flex;
  }

  &__footer {
    display: flex;
  }

  &__price {
    font-weight: 300;
  }

  &__change {
    font-weight: 700;
    margin-left: 8px;

    &.red {
      color: theme('colors.red.500');
    }

    &.green {
      color: theme('colors.green.500');
    }
  }
  &:hover {
    cursor: pointer;
  }
}
