.biggest-table {
  border-radius: 10px;
  padding: 24px;
  margin: 16px 0 16px 0;
  display: flex;
  flex-direction: column;
  min-height: 20rem;
  width: calc(100% / 2 - 16px);
  // min-width: 560px;
  max-width: 100vw !important;
  overflow-x: auto;
  // height: 500px;

  &::-webkit-scrollbar {
    width: 14px;
  }
  & {
    scrollbar-width: thin;
    scrollbar-color: theme('colors.neutral.500') transparent;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 16px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: theme('colors.neutral.500');
    border-radius: 8px;
    border: 3px solid theme('colors.neutral.800');
  }

  &:first-of-type {
    margin-right: 16px;
  }

  &__title {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 20px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    svg {
      margin: 0 8px 0;
    }
  }

  &__subtitle {
    color: theme('colors.neutral.500');
    font-size: 14px;
  }

  &__time-frames {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 16px 0 8px;
  }

  &__time-frame {
    padding: 4px 8px;
    border-radius: 4px;
    font-weight: 700;
    cursor: pointer;
    transition: all 0.3s;
    width: 80px;
    text-align: center;
  }

  &__table {
    margin: 16px 0;
    thead {
      border-bottom: 0.5px solid theme('colors.neutral.400');
    }

    th,
    td {
      height: 2.5rem;
      // padding: 6px 12px;
      text-align: center;
    }

    th {
      color: theme('colors.neutral.500');
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 400;
    }

    tbody tr:hover {
      opacity: 0.7;
    }
  }

  &__th {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__sort-icon {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 4px;
  }

  .green {
    color: theme('colors.green.500');
  }

  .red {
    color: theme('colors.red.500');
  }

  &__pagination-wrapper {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto auto 1px;
  }

  &__pagination {
    margin-left: 64px;
  }

  &__pagination-text {
    width: max-content;
    text-align: center;
  }

  &__coin-logo {
    border-radius: 50%;
    margin: auto;
    width: 28px;
    height: 28px;
  }

  &__time-frames-select {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
}

@media (max-width: 768px) {
  .biggest-table {
    width: 100%;

    &:first-of-type {
      margin-right: 0;
      margin-bottom: 0;
    }
  }
}
