.pagination-container {
  display: flex;
  list-style-type: none;

  .pagination-item {
    padding: 0 12px;
    height: 32px;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    box-sizing: border-box;
    align-items: center;
    font-size: 14px;
    min-width: 32px;
    color: theme('colors.gray.500');

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }
    &:hover {
      color: theme('colors.blue.800');
      cursor: pointer;
    }

    &.selected {
      background-color: theme('colors.neutral.500');
      color: theme('colors.white');
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.6;
    }
  }
}
