.bottom-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__container {
    display: flex;
    align-items: center;
  }

  &__input {
    margin-left: 4px;
    margin-right: 4px;
    border-radius: 10%;
    padding: 7px;
    text-align: center;
  }
}
