.signal-scanner {
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 16px 0 64px;
  // max-width: 1320px;
  // margin: 20px;

  &__table {
    max-width: 100%;
  }

  &__filters {
    color: theme('colors.white');
    border-radius: 16px;
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-wrap: wrap;
  }

  &__filter {
    margin: 8px;
  }

  &__filter-title {
    margin: 0 0 4px 4px;
  }
}
